<template>
  <div class="about">
    <h1>THREE.js Boilerplate</h1>
    <h3>2022 by Patrik H&uuml;bner</h3>
    <p class="infoText">This boilerplate is built to enable rapid development of 3D, WebGL-based generative design systems. It uses a component-based approach to streamline the integration of all "moving parts" by abstracting the frontend, design-logic, render-loop and UI. The system supports routing, multiple instances of sketches, a reactive VueX store, high resolution export capabilities and SASS-based theming.</p>
  </div>
</template>

<style lang="scss">
@import "@/assets/styles/theming.scss";

.about {
  padding: 20px;
}
.infoText {
  @include theme(color, secondaryTextColor);
  font-size: 12px;
  max-width: 400px;
}
</style>